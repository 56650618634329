import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { useDispatch, useSelector } from "react-redux";
import { PaymentActions } from "../utils/store/payments";
import moment, { Moment } from "moment";
import { ToastContainer } from "react-bootstrap";
import { postPayment } from "../utils/server/postPayment";
import { BookingActions } from "../utils/store/booking";
import { successBooking } from "../config/types";

interface stripeSecret {
  clienSecret: string;
}

let bookingStatus: successBooking;



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

//live
const stripePromise = loadStripe(
  "pk_live_51NVMIjF8wBND38gjN8SiSptaXY8TnurcqTZp2ERE2tlu8NPM6IJoJ9LAq7eEG42uoQo5deJL3wQt0k5bKYFzRUYo00Q1IbjHTY"
);


// test
// const stripePromise = loadStripe(
//   "pk_test_51NVMIjF8wBND38gjBJsOxCdk1i5qPs6oAeDke9KrJYPG2WfUwaZw6ZgGsR4WhVSKjA8AbdItcoGPolcjNS75TDn900xbkXgQ8Z"
// );

function Stripe() {
  // Not sure if this is needed
  //   const dispatch = useDispatch();

  //   const clientSecret: string = useSelector((state: any) => {
  //     console.log('clientSecret')
  //     return state.payment.clientSecret;
  //   });

  const dispatch = useDispatch();

  const clientSecret: string = useSelector((state: any) => {
    console.log("clientSecret");
    return state.payment.clientSecret;
  });
  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  useEffect(() => {
    if (clientSecret) {
      console.log("intent found");
      if (
        new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        )
      ) {
        console.log("intent found 2");
      }
    }
    // Create PaymentIntent as soon as the page loads
    else {
      console.log("update clientsecret");

      const res = postPayment(bookingStatus);
      res.then((data) => {
        console.log(data.clienSecret);
        dispatch(PaymentActions.updateSecret(data.clienSecret));
        dispatch(BookingActions.updateIntent(data.clienSecret))
      });
    }
  });
  // This should be dynamic, it should create a payment intent only when all the required fields has values


  

  const appearance: Appearance = {
    theme: "stripe",
    labels: "floating"
  };
  let options: StripeElementsOptions;
  options = {
    clientSecret,
    appearance,
    locale: "nl",
    
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

export default Stripe;
