import axios from 'axios'

axios.defaults.headers.common['x-api-key'] = `UaYyVjRsZT3M8tzYnCwQf9xoCFUtzVAL13a3Y0hP`;
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET,OPTIONS,POST";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, x-apikey, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"

export async function getBookings(){
    const response = await axios.get('https://api.lid.destuivers.be/successpayment', {})
    return response.data
}

// {  headers: {
//     'Access-Control-Allow-Origin': '*',
//     "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, x-apikey, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
//     "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"

//   }}





