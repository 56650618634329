import { createSlice } from "@reduxjs/toolkit";
import { successBooking } from "../../config/types";
import moment, { Moment } from "moment";
import { Satellite } from "@mui/icons-material";

const initialFirstTimeState: successBooking = {
  Intent: "",
  firstName: "",
  lastName: "",
  Email: "",
  Price: "1000",
  Phone: "",
  Challenge: "",
  Course: "",
  Submitted: false,
  agreeRules: false,
};
const bookingSlice = createSlice({
  name: "Booking",
  initialState: initialFirstTimeState,
  reducers: {
    resetBooking: () => initialFirstTimeState,
    updateIntent(state, actions){
      state.Intent = actions.payload
    },
    updateFirstname(state, actions) {
      state.firstName = actions.payload;
    },
    updateLastname(state, actions) {
      state.lastName = actions.payload;
    },
    updateEmail(state, actions) {
      state.Email = actions.payload;
    },
    updatePrice(state, actions) {
      state.Price = actions.payload;
    },
    updateAgreeRules(state, actions) {
      state.agreeRules = actions.payload;
    },
    updateSubmitted(state, actions) {
      state.Submitted = actions.payload;
    },
    updatePhone(state, actions) {
      state.Phone = actions.payload;
    },
    updateChallenge(state, actions) {
      state.Challenge = actions.payload;
    },
    updateCourse(state, actions) {
      state.Course = actions.payload;
    },
  },
});

export const BookingActions = bookingSlice.actions;

export default bookingSlice.reducer;
