import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Checkbox,
  FormGroup,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Form, Row, Container } from "react-bootstrap";
import type {
  Container as ParticleContainer,
  Engine,
} from "tsparticles-engine";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import Particle from "./Particle";
import Schild from "./schild/Schild";
import Stripe from "./booking/Stripe";
import booking, { BookingActions } from "./utils/store/booking";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { successBooking } from "./config/types";

function App() {
  const dispatch = useDispatch();

  let bookingStatus: successBooking;

  bookingStatus = useSelector((state: any) => {
    return state.booking;
  });

  function isValidEmail(email: string): boolean {
    // Regular expression pattern for validating email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the input email against the regex pattern
    return emailRegex.test(email);
  }

  function isValidBelgianPhoneNumber(phoneNumber: string) {
    // Regular expression pattern for Belgian phone numbers
    const belgianPhoneNumberRegex = /^(?:\+32|0)\s?\d(\s?\d){8}$/;

    // Remove any non-numeric characters other than the '+' symbol from the input
    const cleanedPhoneNumber = phoneNumber.replace(/[^\d+]/g, "");

    // Check if the cleaned phone number matches the Belgian phone number pattern
    return belgianPhoneNumberRegex.test(cleanedPhoneNumber);
  }

  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine);

    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: ParticleContainer | undefined) => {
      await console.log(container);
    },
    []
  );

  return (
    <>
      <div className="particles">
        <Particle />
      </div>
      {/* <Container className="custom-center">
      <Row className="justify-content-center">
        <Col md={6}>
          <Form>
            <p>test</p>
          </Form>
        </Col>
      </Row>
    </Container> */}
      <Container
        className="d-flex align-items-center justify-content-center square rounded-8 "
        style={{ minHeight: "100vh" }}
      >
        <div
          className="custom-center justify-content-center align-items-center square rounded-8 "
          style={{ borderRadius: "32px" }}
        >
          <Form className="justify-content-center align-items-center text-center rounded-8 ">

            <h2>Wordt nu lid van de Stuivers!</h2>
            <p style={{ fontSize: "20px" }}>
              Aan de gemeenschappelijk prijs van <b>10 EUR </b>
            </p>
            <Row style={{ marginBottom: "30px" }}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ marginRight: "auto", marginLeft: "20px" }}
                >
                  Opleiding
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={bookingStatus.Course}
                  name="radio-buttons-group"
                  style={{ marginLeft: "20px" }}
                  onChange={(e) => {
                    dispatch(
                      BookingActions.updateCourse(e.currentTarget.value)
                    );
                  }}
                >
                  <FormControlLabel
                    value="Verpleegkunde"
                    control={<Radio />}
                    label="Verpleegkunde (AP Hogeschool)"
                  />
                  <FormControlLabel
                    value="Vroedkunde"
                    control={<Radio />}
                    label="Vroedkunde (AP Hogeschool)"
                  />
                  <FormControlLabel
                    value="Ergotherapie"
                    control={<Radio />}
                    label="Ergotherapie (AP Hogeschool)"
                  />
                  <FormControlLabel
                    value="Toegepaste Psychologie"
                    control={<Radio />}
                    label="Toegepaste Psychologie (AP Hogeschool)"
                  />
                  <FormControlLabel
                    value="Andere "
                    control={<Radio />}
                    label="Andere (werk/andere opleiding/andere educatieve instelling)"
                  />
                </RadioGroup>
              </FormControl>
            </Row>
            <Row style={{ marginBottom: "30px" }}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{ marginRight: "auto", marginLeft: "20px" }}
                >
                  Welke uitdagingen wil je aangaan?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={bookingStatus.Challenge}
                  name="radio-buttons-group"
                  style={{ marginLeft: "20px" }}
                  onChange={(e) => {
                    dispatch(
                      BookingActions.updateChallenge(e.currentTarget.value)
                    );
                  }}
                >
                  <FormControlLabel
                    value="Ik laat me dopen"
                    control={<Radio />}
                    label="Ik laat me dopen"
                  />
                  <FormControlLabel
                    value="Ik word enkel lid
                    "
                    control={<Radio />}
                    label="Ik word enkel lid
                    "
                  />
                  <FormControlLabel
                    value="Praesidium"
                    control={<Radio />}
                    label="Praesidium"
                  />
                  <FormControlLabel
                    value="Ik twijfel en kom graag nog kennismaken tijdens de openingsweek en/of naar het infomoment op 26/09"
                    control={<Radio />}
                    label="Ik twijfel en kom graag nog kennismaken tijdens de openingsweek en/of naar het infomoment op 26/09"
                  />
                </RadioGroup>
              </FormControl>
            </Row>
            <Row style={{ marginLeft: "-4px" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        dispatch(
                          BookingActions.updateAgreeRules(e.target.checked)
                        );
                      }}
                    />
                  }
                  label={
                    <>
                      Ik heb het{" "}
                      <a
                        target="_blank"
                        href="./assets/pdf/Alcohol-en-drugsbeleid.pdf"
                      >
                        alcohol en drugsbeleid
                      </a>{" "}
                      gelezen en ben hiermee akkoord
                    </>
                  }
                />
              </FormGroup>
            </Row>
            <Row style={{ marginBottom: "30px" }}>
              <Col>
                <TextField
                  fullWidth
                  variant="filled"
                  onChange={(e) => {
                    dispatch(BookingActions.updateEmail(e.target.value));
                  }}
                  error={
                    !isValidEmail(bookingStatus.Email) &&
                    bookingStatus.Submitted
                  }
                  required
                  label="Emailadress"
                  style={{
                    marginLeft: "10px",
                    border: "1px solid",
                    backgroundColor: "white !imporant",
                    borderColor: "#e6e6e6",
                  }}
                  value={bookingStatus.Email}
                ></TextField>
              </Col>
              <Col>
                <div id="mydiv">
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Telefoonnummer"
                    required
                    onChange={(e) => {
                      dispatch(BookingActions.updatePhone(e.target.value));
                    }}
                    error={
                      !isValidBelgianPhoneNumber(bookingStatus.Phone) &&
                      bookingStatus.Submitted
                    }
                    style={{
                      border: "1px solid",
                      backgroundColor: "white !imporant",
                      borderColor: "#e6e6e6",
                    }}
                    value={bookingStatus.Phone}
                  ></TextField>
                </div>
              </Col>
            </Row>
            <Row>
              <Stripe></Stripe>
            </Row>
            <Row>
              <Schild />
            </Row>
          </Form>
          <ToastContainer position="bottom-right" />
        </div>
      </Container>
    </>
  );
}

export default App;
