import { useEffect, useState } from "react";

function Schild (){

    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
      const updateContainerHeight = () => {
        const containerWidth = window.innerWidth * 0.3; // Adjust this value to control the width of the container
        const imageAspectRatio = 1.5; // Adjust this value according to your image's aspect ratio
        const newContainerHeight = containerWidth / imageAspectRatio;
        setContainerHeight(newContainerHeight);
      };
  
      // Update container height on mount and window resize
      updateContainerHeight();
      window.addEventListener("resize", updateContainerHeight);
  
      return () => {
        window.removeEventListener("resize", updateContainerHeight);
      };
    }, []);

    return(
        <div
        style={{
          height: containerHeight,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2%",
          marginTop: "2%"
        }}
      >
        <img
          src="/assets/images/schild.png"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: "auto",
            height: "auto",
            objectFit: "contain",
          }}
          alt="My Image"
        />
      </div>
    )
}

export default Schild