import { useEffect, useMemo, useState } from "react";
import MaterialTable, { Column } from "@material-table/core";

import { useDispatch, useSelector } from "react-redux";

interface StudentData {
    Phone:  string ;
    Intent: string ;
    Price: string ;
    Course:  string ;
    Challenge:  string ;
    Email: string ;
  
  }

// Define a row data type that matches the structure of StudentData



const BookingList = () => {

    
    const studentData: StudentData[] = useSelector((state: any) => state.student);
    
    useEffect(() => {
        console.log('exectud')
    }, [studentData.length]);

    const lookup = { true: "Available", false: "Unavailable" };



  // Define columns using MRT_ColumnDef<RowData>
  const columns: Array<Column<StudentData>> = [
    { title: "Email", field: "Email" },
    { title: "Phone", field: "Phone" },
    { title: "Course", field: "Course", type: "numeric" },
    { title: "Challenge", field: "Challenge" },
    { title: "Price", field: "Price" },
  ];

  // Use RowData type for table


  return <MaterialTable columns={columns} data={studentData} isLoading={(studentData.length === 0)} options={{pageSize: 10 }} />;
};

export default BookingList;
