import axios from 'axios'

axios.defaults.headers.common['x-api-key'] = `UaYyVjRsZT3M8tzYnCwQf9xoCFUtzVAL13a3Y0hP`;

export async function getSession(Intent: string){
    console.log(`clientSecret: ${Intent}`)
    const response = await axios.get('https://api.villalubia.com/bookings', {params: {intent: Intent}})
    console.log(response)
    return response.data
}

