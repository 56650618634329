import React, { useState, useEffect } from 'react';
import { getBookings } from '../utils/server/bookings/getBookings';
import BookingList from './BookingList';
import AWS from 'aws-sdk'
import { StudentActions } from '../utils/store/studentslists';
import { useDispatch } from 'react-redux';

interface StudentData {
  Phone:  string ;
  Intent: string ;
  Price: string ;
  Course:  string ;
  Challenge:  string ;
  Email: string ;

}

export let students: StudentData[];

function LoginPage({ onLogin }:any) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e:any) => {
    e.preventDefault();
    // Basic validation
    if (!username || !password) {
      setError('Please enter username and password');
      return;
    }

    // Check credentials (replace this with your actual authentication logic)
    if (username === 'deStuivers' && password === 'deStuivers2024') {
      setError('');
      // Call onLogin callback to notify parent component
      onLogin(username);
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit">Login</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
}

function ProtectedPage({ username, onLogout, studentData } :any) {
  return (
    <div>
      <h2>Welcome, {username}!</h2>
      <p>This is the protected page.</p>
      <BookingList></BookingList>
      <button onClick={onLogout}>Logout</button>
    </div>
  );
}

function LoginHandler() {

  const dispatch = useDispatch();


  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    // Check if user is already logged in (e.g., on page refresh)
    const isLoggedIn = localStorage.getItem('loggedIn') === 'true';
    const storedUsername = localStorage.getItem('username');
    if (isLoggedIn && storedUsername) {
      setLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  const handleLogin = (username:any) => {
    setLoggedIn(true);
    setUsername(username);
    // Store authentication status in localStorage
    localStorage.setItem('loggedIn', 'true');
    localStorage.setItem('username', username);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUsername('');
    // Clear authentication status from localStorage
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('username');
  };

  const getData = async () => {
    try {
      if (loggedIn) {
        const bookings = await getBookings();
        // const test = JSON.parse(bookings)
        students = JSON.parse(bookings).data.filter((item:any) => item.Challenge.S !== '').map((item:any) => ({
          Phone: item.Phone.S ,
          Intent: item.Intent.S ,
          Price: item.Price.S ,
          Course:  item.Course.S ,
          Challenge:  item.Challenge.S ,
          Email: item.Email.S ,
        }))
        console.log(students)
        dispatch(StudentActions.updateList(students))
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  getData()

  return (
    <div>
      {loggedIn ? (
        <ProtectedPage username={username} onLogout={handleLogout}  />
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </div>
  );
}

export default LoginHandler;
