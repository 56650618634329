import { createSlice } from "@reduxjs/toolkit";
import { StudentList } from "../../config/types";
import moment, { Moment } from "moment";
import { Satellite } from "@mui/icons-material";

const initialsStudentList: StudentList[] = [];
const studentSlice = createSlice({
  name: "Student",
  initialState: initialsStudentList,
  reducers: {
    updateList(state, actions) {
        return actions.payload
    },
  },
});

export const StudentActions = studentSlice.actions;

export default studentSlice.reducer;
