import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { successBooking } from "../config/types";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import { postBooking } from "../utils/server/bookings/postBookings";
import { BookingActions } from "../utils/store/booking";

export default function CashCheckoout() {
  let bookingStatus: successBooking;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const pasPass = "Destuivers3120!";
  const [wachtwoord, changePassword] = useState("");

  const handleClickOpen = () => {
    if (
      bookingStatus.agreeRules &&
      bookingStatus.Challenge &&
      bookingStatus.Course &&
      bookingStatus.Phone &&
      bookingStatus.Email
    ) {
      setOpen(true);
    } else {
      toast.error("Please enter provide the required fields", {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  const payCash = async () => {
    if (wachtwoord === pasPass) {
      console.log("password true");
      const response = await postBooking(bookingStatus);
      if (response === 200){
        toast.success("Betaling gelukt!", {
          position: "bottom-right",
          autoClose: 5000,
        });
        handleClose();
        dispatch(BookingActions.resetBooking());
      }

      else {
        toast.error("Unknown error", {
          position: "bottom-right",
          autoClose: 5000,
        });
      }
    } else {
      toast.error("Wrong passwords", {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Pay Cash
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Cash betaling</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Gelieve het onderstaande wachtwoord te laten invullen door het
            Praesidium van De Stuivers. Alvast bedankt!
          </DialogContentText>
          <br></br>
          <DialogContentText>Naam Praesidiumlid:</DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Naam Praesidiumlid"
            type="text"
            fullWidth
            variant="standard"
          />
          <DialogContentText>Wachtwoord:</DialogContentText>
          <TextField
            onChange={(e) => {
              changePassword(e.currentTarget.value);
            }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Wachtwoord"
            type="password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annulleer</Button>
          <Button onClick={payCash}>Betaal</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
