import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { PaymentActions } from "../utils/store/payments";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
// import Cookies from "universal-cookie";
import { getSession } from "../utils/server/session/getSession";
import { FilledInStatus, LubiaSessions, paymentStatus, successBooking, TBooking } from "../config/types";
import { putSession } from "../utils/server/session/putSession";
import { postBooking } from "../utils/server/bookings/postBookings";
import moment from "moment";
import { persistCombineReducers } from "redux-persist";
import booking, { BookingActions } from "../utils/store/booking";
import { TextField } from "@mui/material";
import { Button, Col, Row } from "react-bootstrap";
import Confetti from "react-confetti";
import { postPayment } from "../utils/server/postPayment";
import CashCheckoout from "./CashCheckout";

export default function CheckoutForm() {
  //aws
  const dynamoDb = new AWS.DynamoDB.DocumentClient({ region: "eu-west-1" });
  const TABLE_NAME = "destuivers-prod-payments";

  let bookingStatus: successBooking;

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [paymentState, setPaymentState] = useState<paymentStatus>("")
  const [isLoading, setIsLoading] = useState(false);


  let clientSecret: string;
  let persitentClientSecret: TBooking;
  let paymentSession: string;

  // const clientSecret: string = useSelector((state: any) => {
  //   return state.payment.clientSecret;
  // });

  const sessionId = useSelector((state: any) => {
    console.log(`sessionId ${state.sessionId}`);
    return state.payment.clientSecret;
  });

  persitentClientSecret = useSelector((state: any) => {
    console.log(state);
    return state.payment;
  });

  useEffect(() => {
    const test = () => {
      console.log("date has been changed");
    };
    test();
    // change clientSecret when dates change. //TODO
  }, [persitentClientSecret]);

  bookingStatus = useSelector((state: any) => {
    console.log(state.booking);
    return state.booking;
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const temp = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (temp) {
      clientSecret = temp;
      dispatch(BookingActions.updateIntent(clientSecret));
    }

    console.log(clientSecret);
    console.log(persitentClientSecret);
    if (!clientSecret) {
      return;
    }

    const getIntentStatus = async () => {
      const status: LubiaSessions = await getSession(sessionId);
      return status;
    };

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent)
        switch (paymentIntent.status) {
          case "succeeded":
            (async () => {
              console.log("payment succedded");

              postBooking({
                Intent: paymentIntent.id,
                firstName: bookingStatus.firstName,
                lastName: bookingStatus.lastName,
                Email: bookingStatus.Email,
                Phone: bookingStatus.Phone,
                Price: bookingStatus.Price,
                Challenge: bookingStatus.Challenge,
                Course: bookingStatus.Course,
              });
            })();
            dispatch(PaymentActions.updatePaymentStatus("paid"));
            toast.success("booking has been accepted", {
              position: "bottom-right",
              autoClose: 5000,
            });
            setPaymentState('success')

            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            (async () => {
              console.log("session");
              // const session = await getIntentStatus();
              // console.log(session);
              // if (session.Item.paymentStatus != "failed") {
              //   putSession(
              //     session.Item.Intent,
              //     session.Item.paymentSession,
              //     "failed"
              //   );
              // }
            })();
            toast.error("Betaling mislukt", {
              position: "bottom-right",
              autoClose: 5000,
            });
            setPaymentState('failed')
            setMessage("De betaling is mislukt. Probeer het later opnieuw.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    dispatch(BookingActions.updateSubmitted(true));
    const res = postPayment(bookingStatus);
    res.then((data) => {
      console.log(data.clienSecret);
      dispatch(PaymentActions.updateSecret(data.clienSecret));
      dispatch(BookingActions.updateIntent(data.clienSecret))
    });

    if (
      bookingStatus.agreeRules &&
      bookingStatus.Challenge &&
      bookingStatus.Course &&
      bookingStatus.Phone &&
      bookingStatus.Email
    ) {
      e.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsLoading(true);
      const billingDetails = {
        name: 'null', // Retrieve the name
        email: bookingStatus.Email, // Replace with the actual email
      };


      const { error }: { error: StripeError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: billingDetails,
          },
          return_url: "https://" + window.location.hostname,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        if (error.message) {
          console.log(error);
          setPaymentState('error')

          setMessage(error.message);
        }
      } else {
        console.log(error);
        if (error.payment_intent?.status === "succeeded") {
          toast.success(
            "Bedankt om lid te worden van de club.",
            {
              position: "bottom-right",
              autoClose: 5000,
            }
          );
          setPaymentState('success')

          setMessage(
            "Bedankt om lid te worden van de club."
          );
        } else {
          setPaymentState('error')
          setMessage("An unexpected error occurred.");
        }
      }

      setIsLoading(false);
    } else {
      toast.error("Please enter provide the required fields", {
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Row style={{ margin: "0px" }}>
        <PaymentElement id="payment-element" />
      </Row>

      {/* <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button> */}
      <LoadingButton
        onClick={handleSubmit}
        loading={isLoading}
        loadingIndicator="Loading…"
        variant="outlined"
      >
        Pay Now
      </LoadingButton>
        <CashCheckoout ></CashCheckoout>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message">
          <div>
            {paymentState === 'success' ? (
              <div>
            <p>Bedankt om lid te worden van De Stuivers</p>
            <Confetti></Confetti>
              </div>

            ): paymentState === 'failed' ? (
              <p></p>
            ) : (
              <p>Er was een onverwachte fout</p>
            )}

          </div>
          <div></div>
          {message}
        </div>
      )}
    </form>
  );
}
