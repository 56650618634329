import axios from 'axios'
import moment from 'moment';
import { successBooking } from '../../../config/types';


axios.defaults.headers.common['x-api-key'] = `UaYyVjRsZT3M8tzYnCwQf9xoCFUtzVAL13a3Y0hP`;
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET,OPTIONS,POST";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, x-apikey, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"

// Post to get paymentIntent
export async function postBooking(booking: successBooking){
    const secretIndex = booking.Intent.indexOf("_secret_");

    // If "_secret_" is found, extract the substring before it; otherwise, return the original string
    const result =
      secretIndex !== -1 ? booking.Intent.substring(0, secretIndex) : booking.Intent;
    console.log(booking)
    const response = await axios.post('https://api.lid.destuivers.be/successpayment', {
        "Intent": result,
        "Price": booking.Price,
        "Phone": booking.Phone,
        "Email": booking.Email,
        "Challenge": booking.Challenge,
        "Course" : booking.Course,
        
    })
    console.log(response.data)
    return response.data
}