import axios from 'axios'
import moment from 'moment';
import { successBooking } from '../../config/types';

interface stripeSecret {
    clienSecret: string
  }

axios.defaults.headers.common['x-api-key'] = `UaYyVjRsZT3M8tzYnCwQf9xoCFUtzVAL13a3Y0hP`;
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = "GET,OPTIONS,POST";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, x-apikey, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"

// Post to get paymentIntent
export async function postPayment(booking:successBooking): Promise<stripeSecret>{
  console.log(booking)
    const response = await axios.post('https://api.lid.destuivers.be/payment', {
      "Intent": booking.Intent,
      "Price": booking.Price,
      "Phone": booking.Phone,
      "Email": booking.Email,
      "Challenge": booking.Challenge,
      "Course" : booking.Course,
      
  })
    return response.data
}   