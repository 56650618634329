import {createSlice } from '@reduxjs/toolkit'
import { reactDates, TBooking } from '../../config/types'

const initialFirstTimeState: TBooking = {clientSecret: '', status: null, startDate: null, endDate: null, sessionId: ''}
const paymentSlice = createSlice({
    name: 'Payment',
    initialState: initialFirstTimeState,
    reducers: {
        updateSecret(state, actions){
            state.clientSecret = actions.payload
        },
        updatePaymentStatus(state, actions){
            state.status = actions.payload
        },
        updateDates(state, actions){
            state.startDate = actions.payload.startDate
            state.endDate = actions.payload.endDate
        },
        updateSessionId(state, actions){
            state.sessionId = actions.payload
        }
    }
})

export const PaymentActions = paymentSlice.actions

export default paymentSlice.reducer;
